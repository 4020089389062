<template>
  <AsyncAdminDialog
    ref="dialog"
    :header="`Create a Decorative Title`"
    @agree="agree"
    :disabled="!form_valid"
  >
    <v-form ref="form" v-model="form_valid" @submit.prevent>
      <v-text-field
        label="Title Text"
        v-model="text"
        class="required"
        :rules="[field_req]"
        :counter="max_length"
        :maxlength="max_length"
        autofocus
      />
    </v-form>
  </AsyncAdminDialog>
</template>
<script>
import AsyncAdminDialog from "@/components/base/AsyncAdminDialog";

import { field_req } from "@/utils/form_val_rules";

export default {
  name: "DecorativeTitleCaptureDialog",
  components: {
    AsyncAdminDialog,
  },
  props: {
    max_length: {
      type: Number,
      default: 40,
    },
  },
  data() {
    return {
      field_req,
      form_valid: false,
      text: "",
    };
  },
  methods: {
    clear() {
      this.text = "";
      this.$refs.form.reset();
    },
    agree() {
      this.$refs.dialog.agree({
        title_text: this.text,
      });
      this.clear();
    },
  },
};
</script>

<style lang="scss" scoped>
.img-preview {
  margin: 0 auto;
  background-position: center;
  background-size: cover;
  height: 250px;
  width: 50%;
}
</style>
