<template>
  <AsyncAdminDialog
    ref="dialog"
    :header="`Create simple gallery`"
    @agree="agree"
    :disabled="!form_valid"
  >
    <v-form v-model="form_valid">
      <p class="text-h4 font-weight-bold text-center">
        Images<span class="red--text"> * </span>
      </p>
      <v-alert type="info" colored-border border="left">
        Please select an image which will be used in gallery section
      </v-alert>
      <FileExplorer
        :brand_id="selected_brand.id"
        :max="3"
        :multi_select="true"
        @select-images="(_images) => (images = _images)"
        :label="images.length ? 'Change Images' : 'Select Images'"
        :wide-rectangle="false"
        :rectangle="true"
        :square="false"
      />
      <div
        v-if="images.length"
        class="preview-container d-flex justify-space-between"
      >
        <div
          v-for="(img, idx) in images"
          :key="`img_${idx}`"
          :style="`backgroundImage: url(${img.small_url})`"
          class="img-preview mr-2 br-8"
        />
      </div>
    </v-form>
  </AsyncAdminDialog>
</template>

<script>
import AsyncAdminDialog from "@/components/base/AsyncAdminDialog";
import FileExplorer from "@/components/file_manager/FileExplorer";

import { field_req } from "@/utils/form_val_rules";
import { mapState } from "vuex";

export default {
  name: "GalleryCaptureDialog",
  components: {
    AsyncAdminDialog,
    FileExplorer,
  },
  data() {
    return {
      field_req,

      form_valid: false,
      images: [],
    };
  },
  computed: {
    ...mapState("SuperAdminStore", ["selected_brand"]),
  },
  methods: {
    clear() {
      this.images = [];
    },
    agree() {
      this.$refs.dialog.agree({
        images: this.images,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.preview-container {
  overflow-x: auto;
  .img-preview {
    margin: 0 auto;
    background-position: center;
    background-size: cover;
    height: 150px;
    min-width: 150px;
  }
}
</style>
