<template>
  <AsyncAdminDialog
    ref="dialog"
    :header="`Create a Team Photo Gallery`"
    :disabled="!is_valid"
    @agree="agree"
    @close="clear"
  >
    <div class="dialog-wrapper">
      <FileExplorer
        class="mb-4"
        :brand_id="selected_brand.id"
        :label="selected_images.length ? 'Change Images' : 'Select Images'"
        :multi_select="true"
        :max="3"
        @select-images="(images) => (selected_images = images)"
        :wide-rectangle="false"
        :rectangle="false"
        :square="true"
      />
      <div
        v-if="selected_images.length"
        class="team-images-wrapper grey-scroll-bar"
      >
        <v-form v-model="is_valid" @submit.prevent class="mr-5">
          <div
            v-for="(image, i) in selected_images"
            :key="`team_image_${i}`"
            class="d-flex align-center justify-center mb-3"
          >
            <div
              class="photo-preview br-8"
              :style="`background-image: url(${image.small_url});`"
            ></div>
            <div class="ml-3 d-flex flex-column align-center justify-center">
              <v-text-field
                label="Name"
                class="required"
                v-model="image_data[i].name"
                :rules="[field_req]"
                :counter="max_text_length"
                :maxlength="max_text_length"
              />
              <v-text-field
                label="Title"
                v-model="image_data[i].title"
                :counter="max_text_length"
                :maxlength="max_text_length"
              />
            </div>
          </div>
        </v-form>
      </div>
    </div>
  </AsyncAdminDialog>
</template>
<script>
import { mapState } from "vuex";

import { field_req } from "@/utils/form_val_rules";
import AsyncAdminDialog from "@/components/base/AsyncAdminDialog";
import FileExplorer from "@/components/file_manager/FileExplorer";

export default {
  name: "TeamGalleryCaptureDialog",
  components: {
    AsyncAdminDialog,
    FileExplorer,
  },
  props: {
    max_text_length: {
      type: Number,
      default: 25,
    },
  },
  data() {
    return {
      selected_images: [],
      image_data: [],
      is_valid: false,
      field_req,
    };
  },
  computed: {
    ...mapState("SuperAdminStore", ["selected_brand"]),
  },
  methods: {
    clear() {
      this.selected_images = [];
      this.image_data = [];
    },
    agree() {
      let image_data = [];
      for (const [i, image] of this.selected_images.entries()) {
        image_data.push({ ...this.image_data[i], image: image });
      }
      this.$refs.dialog.agree({
        image_data,
      });
      this.clear();
    },
  },
  watch: {
    selected_images(images) {
      this.image_data = [];
      if (!images.length) return;
      images.forEach(() => {
        this.image_data.push({ name: "", title: "" });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-wrapper {
  overflow: hidden;
}
.photo-preview {
  margin: 0 auto;
  background-position: center;
  background-size: cover;
  height: 200px;
  width: 200px;
}
.team-images-wrapper {
  max-height: 600px;
  overflow-x: hidden;
}
</style>
