<template>
  <div>
    <v-md-editor
      v-model="_value"
      height="600px"
      :toc-nav-position-right="false"
      :left-toolbar="left_toolbar"
      :toolbar="toolbar"
    ></v-md-editor>
    <!--  -->
    <CallToActionCaptureDialog ref="cta" :with-image="cta_with_image" />
    <GalleryCaptureDialog ref="gallery" />
    <ImageCaptureDialog ref="insert_img" />
    <DecorativeTitleCaptureDialog ref="deco_title" />
    <TeamGalleryCaptureDialog ref="team_gallery" />
  </div>
</template>

<script>
import CallToActionCaptureDialog from "@/views/admin/components/news/CallToActionCaptureDialog";
import GalleryCaptureDialog from "@/views/admin/components/news/GalleryCaptureDialog";
import ImageCaptureDialog from "@/views/admin/components/news/ImageCaptureDialog";
import DecorativeTitleCaptureDialog from "@/views/admin/components/news/DecorativeTitleCaptureDialog";
import TeamGalleryCaptureDialog from "@/views/admin/components/news/TeamGalleryCaptureDialog";

/* 
  For more documentation on "v-md-editor" visit https://code-farmer-i.github.io/vue-markdown-editor/
 */

function create_image_element(image) {
  return {
    text: `<img src="${image.small_url}" />\n`,
    selected: "",
  };
}
function create_simple_call_to_action(
  cta_title,
  cta_text_align,
  cta_bg_color,
  cta_btn_color,
  cta_text,
  cta_action_text,
  cta_action_link
) {
  let btn_border_style =
    cta_btn_color == "grey-btn" && cta_bg_color == "primary"
      ? 'style="border: none;"'
      : "";
  return {
    text:
      "<!-- SIMPLE CALL TO ACTION STARTS --> \n" +
      `<div class="call-to-action ${cta_bg_color}" style="${
        cta_bg_color == "primary" ? "color: white; " : ""
      } text-align: ${cta_text_align}; "><div>` +
      (cta_title ? `<span class="cta-title">${cta_title}</span>` : "") +
      `<p>${cta_text}</p>` +
      `<a href="${cta_action_link}" alt="${cta_action_text}" class="${cta_btn_color}" ${btn_border_style}>${cta_action_text}</a>` +
      "</div></div> \n" +
      "<!-- SIMPLE CALL TO ACTION ENDS -->\n",
    selected: "",
  };
}
function create_call_to_action_with_image(
  cta_text,
  cta_action_text,
  cta_action_link,
  cta_image_url
) {
  return {
    text:
      "<!-- CALL TO ACTION WITH IMAGE STARTS --> \n" +
      '<div class="call-to-action --with-image primary" style="color: white;">' +
      `<div style="background-image: url(${cta_image_url})"></div>` +
      "<div>" +
      `<p>${cta_text}</p>` +
      `<a href="${cta_action_link}" alt="${cta_action_text}">${cta_action_text}</a>` +
      "</div>" +
      "</div> \n" +
      "<!-- CALL TO ACTION WITH IMAGE ENDS -->\n",
    selected: "",
  };
}
function create_gallery(images) {
  let text =
    "<!-- SIMPLE GALLERY --> \n" +
    '<div class="gallery">' +
    '<div class="--wrapper">';

  images.forEach((img) => {
    text += `<div style="background-image: url(${img.small_url});"></div>`;
  });
  text += "</div>";
  text += "</div> \n" + "<!-- GALLERY ENDS -->\n";
  return {
    text,
    selected: "",
  };
}

function create_team_gallery(image_data) {
  let flex_helper = image_data.length > 1 ? "d-md-flex" : "d-flex";
  let team_photos_markdown = "";
  let team_photo;
  image_data.forEach((data) => {
    team_photo = `<div class="team-photo" style="background-image: url(${data.image.small_url});">`;
    team_photo += `<div class="photo-text"> <span>${data.name}</span>`;
    team_photo += data.title
      ? `<span class="photo-title">${data.title}</span></div>`
      : "</div>";
    team_photo += "</div>";
    team_photos_markdown += team_photo;
  });
  return {
    text:
      "\n<!-- TEAM GALLERY STARTS -->\n" +
      `<div class="team-gallery-wrapper grey-scroll-bar ${flex_helper} align-center-justify-center"><div>` +
      `<div class="team-gallery" style="grid-template-columns: repeat(${Math.min(
        image_data.length,
        3
      )}, 305px);">` +
      team_photos_markdown +
      "</div>" +
      "</div></div>\n" +
      "<!-- TEAM GALLERY ENDS -->\n",
    selected: "",
  };
}

function create_decorative_text(text) {
  return {
    text:
      "\n<!-- DECORATIVE TITLE STARTS -->\n" +
      '<div class="post-title" style="text-align: center;">' +
      `<h2 class="title-effect black--text px-2 text-h2 font-weight-bold" >${text}</h2></div>\n` +
      "<!-- DECORATIVE TITLE ENDS -->\n",
    selected: "",
  };
}

export default {
  name: "MarkdownEditor",
  components: {
    CallToActionCaptureDialog,
    GalleryCaptureDialog,
    ImageCaptureDialog,
    DecorativeTitleCaptureDialog,
    TeamGalleryCaptureDialog,
  },
  props: {
    value: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      cta_with_image: false,
      toolbar: {
        img: {
          icon: "v-md-icon-img",
          title: "Insert Image",
          action: async (editor) => {
            const { image } = await this.$refs.insert_img.$refs.dialog.open();
            editor.insert(() => create_image_element(image));
          },
        },
        "custom-components": {
          title: "Add Custom News Elements",
          icon: "v-md-icon-tip",
          menus: [
            {
              text: "Add simple call-to-action",
              action: async (editor) => {
                const {
                  cta_title,
                  cta_text_align,
                  cta_bg_color,
                  cta_btn_color,
                  cta_text,
                  cta_action_text,
                  cta_action_link,
                } = await this.$refs.cta.$refs.dialog.open();
                editor.insert(() =>
                  create_simple_call_to_action(
                    cta_title,
                    cta_text_align,
                    cta_bg_color,
                    cta_btn_color,
                    cta_text,
                    cta_action_text,
                    cta_action_link
                  )
                );
              },
            },
            {
              text: "Add call-to-action with image",
              action: async (editor) => {
                this.cta_with_image = true;
                const {
                  cta_text,
                  cta_action_text,
                  cta_action_link,
                  cta_image_url,
                } = await this.$refs.cta.$refs.dialog.open();
                editor.insert(() =>
                  create_call_to_action_with_image(
                    cta_text,
                    cta_action_text,
                    cta_action_link,
                    cta_image_url
                  )
                );
                this.cta_with_image = false;
              },
            },
            {
              text: "Add decorative title",
              action: async (editor) => {
                const { title_text } =
                  await this.$refs.deco_title.$refs.dialog.open();
                editor.insert(() => create_decorative_text(title_text));
              },
            },
            {
              text: "Add simple gallery",
              action: async (editor) => {
                const { images } = await this.$refs.gallery.$refs.dialog.open();
                editor.insert(() => create_gallery(images));
              },
            },
            {
              text: "Add team photo gallery",
              action: async (editor) => {
                const { image_data } =
                  await this.$refs.team_gallery.$refs.dialog.open();
                editor.insert(() => create_team_gallery(image_data));
              },
            },
          ],
        },
      },
    };
  },
  computed: {
    _value: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
    left_toolbar() {
      return [
        "undo redo clear",
        "h bold italic strikethrough quote",
        "ul ol",
        "link",
        "img",
        "custom-components",
      ].join(" | ");
    },
  },
};
</script>

<style lang="scss">
$margin-bottom: 3.3rem;
$max-width: 970px;
$border-radius: 16px;

.vuepress-markdown-body {
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  ol,
  ul,
  img,
  header h1,
  header p,
  .call-to-action span,
  .post-title {
    max-width: $max-width;
    margin: 0 1rem $margin-bottom 1rem;

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      margin: 0 auto $margin-bottom auto;
    }
  }
  h1 {
    font-size: 1.71rem;
  }
  p,
  time {
    font-size: 1.2rem;
  }
  img {
    max-width: calc(100% - 2rem);
    display: block;
    border-radius: $border-radius;

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      max-width: 100%;
    }
  }
  blockquote {
    text-align: center;
    p {
      font-size: 2rem;
    }
  }
  .header-img {
    width: 100%;
    height: 380px;
    background-size: cover;
    background-position: center;
    margin-bottom: $margin-bottom;
  }
  .controls-wrapper {
    margin: 0 auto;
    max-width: $max-width;
    position: relative;
    .--left {
      @media #{map-get($display-breakpoints, 'md-and-up')} {
        position: absolute;
        left: -140px;
      }
    }
    .--right {
      @media #{map-get($display-breakpoints, 'md-and-up')} {
        position: absolute;
        right: -140px;
      }
    }
  }
  .call-to-action {
    padding: 5rem 0;
    font-weight: bold;
    text-align: center;
    margin-bottom: $margin-bottom;

    & > div {
      max-width: $max-width;
      margin: 0 auto;
    }

    a {
      margin: 0 1rem 0 1rem;
      @media #{map-get($display-breakpoints, 'md-and-up')} {
        margin: 0;
      }
    }

    p {
      font-weight: normal;
      margin-bottom: 2rem;
      @media #{map-get($display-breakpoints, 'md-and-up')} {
        font-size: 1.5rem;
      }
    }
    a {
      display: inline-block;
      text-decoration: none;
      font-size: 1.4rem;
      padding: 0.8rem 1.3rem;
      border: 2px solid;
      border-radius: 30px;

      @media #{map-get($display-breakpoints, 'md-and-up')} {
        padding: 0.8rem 1.5rem;
        font-size: 1.75rem;
      }

      &.primary-btn {
        background: var(--v-primary-base);
        color: white;
        border: none;
      }
      &.grey-btn {
        background: #eeeeee;
        color: black;
        border: none;
      }
    }

    .cta-title {
      display: block;
      font-weight: bold;
      font-size: 1.6rem;
      margin-bottom: 35px;

      @media #{map-get($display-breakpoints, 'md-and-up')} {
        font-size: 2.2rem;
      }
    }

    &.--with-image {
      padding: 0;
      border-radius: $border-radius;
      margin: 0 1rem $margin-bottom 1rem;
      max-width: $max-width;
      display: grid;
      grid-template-columns: minmax(0, 1fr);

      @media #{map-get($display-breakpoints, 'md-and-up')} {
        margin: 0 auto $margin-bottom auto;
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }

      div:first-of-type {
        height: 230px;
        width: 100%;
        background-position: center;
        background-size: cover;
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;

        @media #{map-get($display-breakpoints, 'md-and-up')} {
          border-top-right-radius: 0;
          border-top-left-radius: $border-radius;
          border-bottom-left-radius: $border-radius;
          height: unset;
        }
      }

      div:last-of-type {
        padding: 3.5rem 2rem;
        color: white;
        @media #{map-get($display-breakpoints, 'md-and-up')} {
          padding: 3.7rem;
        }
      }
      a {
        color: white;
        border-color: white;
      }
    }
  }
  .gallery {
    max-width: $max-width;
    margin: 0 auto;
    margin-bottom: $margin-bottom;

    .--wrapper {
      margin: 0 1rem;
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 3fr));
      gap: 1.7rem;

      div {
        border-radius: 16px;
        background-size: cover;
        background-position: center;
        height: 100px;
        @media #{map-get($display-breakpoints, 'md-and-up')} {
          height: 305px;
        }
      }
    }
  }
  .team-gallery-wrapper {
    justify-content: center;
    align-items: center;
    margin: 0 auto $margin-bottom auto;
    & > div {
      display: inline-block;
      padding: 10px;
    }
    &::-webkit-scrollbar {
      height: 4px;
    }
    .team-gallery {
      display: grid;
      column-gap: 21px;
      row-gap: 21px;
      .team-photo {
        background-size: cover;
        background-position: center;
        height: 305px;
        width: 305px;
        border-radius: 15px;
        display: flex;
        &:hover {
          .photo-text {
            opacity: 0.76;
          }
        }
        .photo-text {
          transition: opacity 300ms ease-in-out;
          align-self: flex-end;
          width: 100%;
          height: 55px;
          background-color: #fff;
          opacity: 0;
          backdrop-filter: blur(30px);
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          span {
            font-size: 18px;
            line-height: 1.22;
            &.photo-title {
              font-weight: bold;
            }
          }
        }
      }
    }
  }
  .title-effect {
    display: inline;
    box-decoration-break: clone;
    background: linear-gradient(
      rgba(0, 0, 0, 0) 32%,
      var(--v-primary-base) 32%,
      var(--v-primary-base) 90%,
      rgba(0, 0, 0, 0) 90%
    );
    border-radius: 99px;
  }
}
</style>
