<template>
  <v-form class="blog-post-form mb-8" v-model="is_form_valid">
    <div class="bordered pa-8 mb-4">
      <p class="text-h4 font-weight-bold text-center">
        {{
          is_editing
            ? `Edit ${this.customPage ? "Page" : "Post"}`
            : `Create New ${this.customPage ? "Page" : "Post"} for ${
                selected_brand.name
              }`
        }}
      </p>
      <v-text-field
        v-if="customPage"
        v-model="post.nav_label"
        autocomplete="new-password"
        label="Title"
        class="required"
        counter="20"
        :rules="this.customPage ? [field_req, (v) => length_max(v, 20)] : []"
        required
        @input="(v) => (post.nav_slug = format_string_to_uid(v.trim()))"
      />
      <v-text-field
        v-else
        v-model="post.title"
        autocomplete="new-password"
        label="Title"
        class="required"
        :rules="this.customPage ? [] : [field_req]"
        required
        @input="(v) => (post.slug = format_string_to_uid(v.trim()))"
      />
      <v-text-field
        v-if="customPage"
        v-model="post.nav_slug"
        class="mb-2 nav-slug-field"
        readonly
        disabled
        autocomplete="new-password"
        label="Slug"
        :rules="[nav_slug_is_unique]"
      />
      <v-text-field
        v-else
        v-model="post.slug"
        readonly
        disabled
        autocomplete="new-password"
        label="Slug"
      />
      <v-text-field
        v-model="post.author"
        autocomplete="new-password"
        label="Author"
        class="required"
        required
      />
    </div>
    <div class="bordered pa-8 mb-4">
      <p class="text-h4 font-weight-bold text-center">
        {{ this.customPage ? "Page" : "Post" }} Editor
      </p>
      <v-alert type="info" colored-border border="left">
        Below is a Rich Text Editor. To use it effectively, you will need some
        basic knowledge of
        <a
          href="https://developer.mozilla.org/en-US/docs/Web/HTML"
          target="_blank"
          >HTML</a
        >
        and
        <a href="https://en.wikipedia.org/wiki/Markdown" target="_blank"
          >Markdown</a
        >
        . For the best editing experience, try it in
        <b>full-screen mode</b>.
      </v-alert>
      <MarkdownEditor
        v-model="post.content"
        @change="(content) => (post.content = content)"
      />
    </div>
    <div class="bordered pa-8 mb-4">
      <p class="text-h4 font-weight-bold text-center pb-3">
        Search Engine Optimization
      </p>
      <v-text-field
        v-if="customPage"
        v-model="post.nav_label"
        readonly
        disabled
        autocomplete="new-password"
        label="Meta Title"
      />
      <v-text-field
        v-else
        v-model="post.title"
        readonly
        disabled
        autocomplete="new-password"
        label="Meta Title"
      />
      <v-textarea
        v-model="post.description"
        autocomplete="new-password"
        label="Meta Description"
        :placeholder="`This is a text snippet of your ${
          this.customPage ? 'page' : 'post'
        } which appears under your title in any search engine results. Usually, it is around 1-2 sentences`"
        class="required pb-5"
        counter="120"
        required
      />
      <span class="light-grey-text" style="font-size: 11px"
        >Featured Image <span class="red--text">*</span>
      </span>
      <v-alert type="info" colored-border border="left">
        This image will be used as your header image and thumbnail previews.
        Make sure it is a good quality image, more than 1080px.
      </v-alert>
      <FileExplorer
        :brand_id="selected_brand.id"
        :label="preview_img_url ? 'Change Image' : 'Select Image'"
        @select-image="
          (image) => {
            post.image_id = image.id;
            preview_image = image;
          }
        "
        :square="false"
        :rectangle="false"
        :wide-rectangle="true"
      />
      <div
        v-if="preview_image"
        class="post-img-preview br-16"
        :style="`background-image: url(${preview_img_url});`"
      />
    </div>
    <div class="bordered pa-8 mb-4">
      <p class="text-h4 font-weight-bold text-center">Publishing Information</p>
      <v-alert type="warning" color="accent" colored-border border="left">
        <b>Please note:</b> In order to publish your
        {{ this.customPage ? "page" : "post" }}, you will need to manually press
        the publish toggle {{ this.customPage ? "" : "in your news tab" }} AFTER
        you've added the {{ this.customPage ? "page" : "post" }}.
      </v-alert>
      <div class="d-flex mb-6" v-if="!customPage">
        <v-menu
          v-model="publish_date_menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="
                publish_date ? moment(publish_date).format('DD/MM/YYYY') : null
              "
              label="Publish Date"
              class="required"
              required
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="publish_date"
            no-title
            @input="publish_date_menu = false"
          ></v-date-picker>
        </v-menu>
        <v-select
          v-model="publish_time"
          label="Publish Time"
          required
          :items="HOURS"
          class="required pl-4"
        />
      </div>
      <v-alert type="info" colored-border border="left">
        You can select an expiration date if you wish for the
        {{ this.customPage ? "page" : "post" }}
        to be hidden after a certain point in time. If you'd like the
        {{ this.customPage ? "page" : "post" }} to be visible at all times,
        leave it blank.
      </v-alert>
      <div class="d-flex">
        <v-menu
          v-model="expire_date_menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="
                expire_date ? moment(expire_date).format('DD/MM/YYYY') : null
              "
              label="Expire Date"
              clearable
              @click:clear="
                expire_time = null;
                expire_date = null;
              "
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="expire_date"
            no-title
            @input="expire_date_menu = false"
          ></v-date-picker>
        </v-menu>
        <v-select
          v-model="expire_time"
          label="Expire Time"
          :items="HOURS"
          class="pl-4"
          clearable
          @click:clear="
            expire_time = null;
            expire_date = null;
          "
        />
      </div>
    </div>
    <v-btn
      block
      rounded
      class="my-6 mr-0"
      color="primary"
      :disabled="!can_add_post"
      @click="upsert_post"
    >
      <v-progress-circular
        v-if="processing_post"
        size="25"
        color="primary"
        indeterminate
      />
      <span v-else>{{
        is_editing
          ? `Update ${this.customPage ? "Page" : "Post"}`
          : `Add New ${this.customPage ? "Page" : "Post"}`
      }}</span></v-btn
    >
  </v-form>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import moment from "moment";

import { upsert_blog_post, get_blog_post_by_id } from "@/requests";
import { field_req, length_max } from "@/utils/form_val_rules";
import { format_string_to_uid } from "@/utils/firestore";

import MarkdownEditor from "@/views/admin/components/news/MarkdownEditor";
import FileExplorer from "@/components/file_manager/FileExplorer";

const HOURS = [
  "00:00",
  "01:00",
  "02:00",
  "03:00",
  "04:00",
  "05:00",
  "06:00",
  "07:00",
  "08:00",
  "09:00",
  "10:00",
  "11:00",
  "12:00",
  "13:00",
  "14:00",
  "15:00",
  "16:00",
  "17:00",
  "18:00",
  "19:00",
  "20:00",
  "21:00",
  "22:00",
  "23:00",
];

export default {
  name: "NewsPostForm",
  components: {
    MarkdownEditor,
    FileExplorer,
  },
  props: {
    customPage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      HOURS,
      moment,
      format_string_to_uid,
      field_req,
      length_max,
      processing_post: false,
      preview_image: {},

      publish_date_menu: false,
      publish_date: null,
      publish_time: null,

      expire_date_menu: false,
      expire_date: null,
      expire_time: null,

      is_form_valid: false,
      post: {
        title: null,
        slug: null,
        description: "",
        is_active: false,
        content: "",
        image_id: null,
        nav_label: null,
        nav_slug: null,
      },
    };
  },
  computed: {
    ...mapState("SuperAdminStore", ["selected_brand"]),
    ...mapGetters("UserStore", { user_name: "name" }),
    is_editing() {
      return Boolean(this.$route?.query?.id ?? false);
    },
    _post_type() {
      return this.customPage ? "custom_page" : "article";
    },
    preview_img_url() {
      const img = this.preview_image;
      if (img.big_url) {
        return img.medium_url;
      }
      if (img.medium_url) return img.medium_url;
      return img.small_url;
    },
    can_add_post() {
      return (
        this.post.image_id !== null &&
        this.post.content !== "" &&
        this.is_form_valid
      );
    },
    publish_at() {
      if (this.customPage) return `${moment().format("YYYY-MM-DD")}T00:00:00Z`;
      const date = this.publish_date;
      if (!date) return null;
      const time = this.publish_time;
      if (!time) return date;
      return `${date}T${time}:00Z`;
    },
    expire_at() {
      const date = this.expire_date;
      if (!date) return null;
      const time = this.expire_time;
      if (!time) return date;
      return `${date}T${time}:00Z`;
    },
  },
  async mounted() {
    if (this.is_editing) {
      let { data: post } = await get_blog_post_by_id(this.$route.query.id);
      this.publish_date = post.publish_at.slice(0, 10);
      this.publish_time = post.publish_at.slice(11, 16);

      if (post.expire_at) {
        this.expire_date = post.expire_at.slice(0, 10);
        this.expire_time = post.expire_at.slice(11, 16);
      }
      this.post.image_id = post.image.id;
      this.preview_image = post.image;
      this.post = { ...post };
    } else {
      this.post.author = this.user_name;
    }
  },
  methods: {
    ...mapActions("SuperAdminStore", ["get_brands", "upsert_custom_page"]),
    ...mapActions("ImageStore", ["toggle_show_explorer"]),
    nav_slug_is_unique(slug) {
      if (!this.customPage || !slug) return true;
      const existing_pages = this.is_editing
        ? this.selected_brand.custom_pages.filter((p) => p.id != this.post.id)
        : this.selected_brand.custom_pages;
      const existing_nav_slugs = existing_pages.map((p) => p.nav_slug);
      return (
        !existing_nav_slugs.includes(slug) ||
        "Slugs must be unique for each custom page, please pick a different title"
      );
    },
    async upsert_post() {
      this.processing_post = true;
      try {
        let post_in = { ...this.post };
        post_in.publish_at = this.publish_at;
        post_in.expire_at = this.expire_at;
        post_in.post_type = this._post_type;

        post_in.brand_id = this.selected_brand.id;

        if (this.customPage) {
          await this.upsert_custom_page({ page: post_in });
        } else {
          await upsert_blog_post(post_in);
        }
        this.$router.replace({
          name: this.customPage
            ? "admin-brands-id-pages"
            : "admin-brands-id-news",
          params: { brand_id: this.selected_brand.id },
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.processing_post = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bordered {
  background-color: white;
  border-radius: 10px;
}
.light-grey-text {
  color: #00000099;
}
.blog-post-form {
  width: 50vw;
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    width: unset;
  }

  .disabled {
    cursor: not-allowed;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      inset: 0;
      background-color: rgba(238, 238, 238, 0.3);
      z-index: 10;
    }
  }

  .post-img-preview {
    margin: 0 auto;
    background-position: center;
    background-size: cover;
    height: 250px;
    width: 100%;
  }
}
::v-deep .nav-slug-field {
  .v-messages__message {
    color: var(--v-error-base);
  }
}
</style>
