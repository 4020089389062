<template>
  <AsyncAdminDialog
    ref="dialog"
    :header="`Insert image`"
    @agree="agree"
    :disabled="!form_valid"
  >
    <v-form v-model="form_valid">
      <p class="text-h4 font-weight-bold text-center">
        Image<span class="red--text"> * </span>
      </p>
      <v-alert type="info" colored-border border="left">
        Please select an image
      </v-alert>
      <FileExplorer
        :brand_id="selected_brand.id"
        @select-image="(_image) => (image = _image)"
        :label="image ? 'Change Image' : 'Select Image'"
        :wide-rectangle="true"
        :rectangle="false"
        :square="false"
      />
      <div v-if="image" class="preview-container">
        <div
          :style="`backgroundImage: url(${image.small_url})`"
          class="img-preview mr-2 br-8"
        />
      </div>
    </v-form>
  </AsyncAdminDialog>
</template>

<script>
import AsyncAdminDialog from "@/components/base/AsyncAdminDialog";
import FileExplorer from "@/components/file_manager/FileExplorer";

import { field_req } from "@/utils/form_val_rules";
import { mapState } from "vuex";

export default {
  name: "ImageCaptureDialog",
  components: {
    AsyncAdminDialog,
    FileExplorer,
  },
  data() {
    return {
      field_req,

      form_valid: false,
      image: null,
    };
  },
  computed: {
    ...mapState("SuperAdminStore", ["selected_brand"]),
  },
  methods: {
    clear() {
      this.image = null;
    },
    agree() {
      this.$refs.dialog.agree({
        image: this.image,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.preview-container {
  overflow-x: auto;
  .img-preview {
    margin: 0 auto;
    background-position: center;
    background-size: cover;
    height: 150px;
    min-width: 150px;
  }
}
</style>
