<template>
  <AsyncAdminDialog
    ref="dialog"
    :header="`Create Call-to-action section ${withImage ? 'with image' : ''}`"
    @agree="agree"
    :disabled="!form_valid"
  >
    <v-form v-model="form_valid">
      <template v-if="withImage">
        <p class="text-h4 font-weight-bold text-center">
          Image<span class="red--text"> * </span>
        </p>
        <v-alert type="info" colored-border border="left">
          Please select an image which will be used in call-to-action section
        </v-alert>
        <FileExplorer
          :brand_id="selected_brand.id"
          @select-image="(image) => (cta_image_url = image.small_url)"
          :label="cta_image_url ? 'Change Image' : 'Select Image'"
          :wide-rectangle="false"
          :rectangle="false"
          :square="true"
        />
        <div
          v-if="cta_image_url"
          class="img-preview br-16"
          :style="`background-image: url(${cta_image_url});`"
        />
      </template>
      <v-radio-group
        v-if="!withImage"
        v-model="cta_bg_color"
        required
        hide-details
        row
        :rules="[field_req]"
      >
        <template v-slot:label>
          <div>Background color <span class="red--text">*</span></div>
        </template>
        <v-radio label="Brand Theme" value="primary"></v-radio>
        <v-radio label="Light Grey" value="grey lighten-3"></v-radio>
      </v-radio-group>
      <v-radio-group
        v-if="!withImage"
        v-model="cta_btn_color"
        required
        hide-details
        row
        :rules="[field_req]"
      >
        <template v-slot:label>
          <div>Button color <span class="red--text">*</span></div>
        </template>
        <v-radio label="Brand Theme" value="primary-btn"></v-radio>
        <v-radio label="Light Grey" value="grey-btn"></v-radio>
      </v-radio-group>
      <v-radio-group
        class="pb-5"
        v-if="!withImage"
        v-model="cta_text_align"
        required
        hide-details
        row
        :rules="[field_req]"
      >
        <template v-slot:label>
          <div>Alignment <span class="red--text">*</span></div>
        </template>
        <v-radio label="Left" value="left"></v-radio>
        <v-radio label="Center" value="center"></v-radio>
      </v-radio-group>
      <v-text-field label="Call-to-action title" v-model="cta_title" />
      <v-textarea
        label="Paragraph"
        v-model="cta_text"
        class="required"
        :rules="[field_req]"
      />
      <v-text-field
        label="Call-to-action text"
        v-model="cta_action_text"
        class="required"
        :rules="[field_req]"
      />
      <v-text-field
        label="Call-to-action link"
        v-model="cta_action_link"
        class="required"
        :rules="[field_req]"
      />
    </v-form>
  </AsyncAdminDialog>
</template>

<script>
import AsyncAdminDialog from "@/components/base/AsyncAdminDialog";
import FileExplorer from "@/components/file_manager/FileExplorer";

import { field_req } from "@/utils/form_val_rules";
import { mapActions, mapState } from "vuex";

export default {
  name: "SimpleCallToActionCaptureDialog",
  components: {
    AsyncAdminDialog,
    FileExplorer,
  },
  props: {
    withImage: {
      type: Boolean,
    },
  },
  data() {
    return {
      field_req,

      form_valid: false,
      cta_text_align: "center",
      cta_bg_color: "primary",
      cta_btn_color: "primary-btn",
      cta_image_url: null,
      cta_title: null,
      cta_text: null,
      cta_action_text: null,
      cta_action_link: null,
    };
  },
  computed: {
    ...mapState("SuperAdminStore", ["selected_brand"]),
  },
  methods: {
    ...mapActions("ImageStore", ["toggle_show_explorer"]),
    clear() {
      this.cta_text_align = "center";
      this.cta_bg_color = "primary";
      this.cta_btn_color = "primary-btn";
      this.cta_image_url = null;
      this.cta_title = null;
      this.cta_text = null;
      this.cta_action_text = null;
      this.cta_action_link = null;
    },
    agree() {
      if (this.withImage) {
        this.$refs.dialog.agree({
          cta_image_url: this.cta_image_url,
          cta_text: this.cta_text,
          cta_action_text: this.cta_action_text,
          cta_action_link: this.cta_action_link,
        });
      } else {
        this.$refs.dialog.agree({
          cta_title: this.cta_title,
          cta_text_align: this.cta_text_align,
          cta_bg_color: this.cta_bg_color,
          cta_btn_color: this.cta_btn_color,
          cta_text: this.cta_text,
          cta_action_text: this.cta_action_text,
          cta_action_link: this.cta_action_link,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.img-preview {
  margin: 0 auto;
  background-position: center;
  background-size: cover;
  height: 250px;
  width: 50%;
}
</style>
